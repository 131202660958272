// DetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllPrice from './AllPrice';
import BasicDetail from './BasicDetail';
import NetValue from './NetValue';
import ChangeRate from './ChangeRate';
import HistoryCB from './HistoryCB';
import { putInpQueryParam } from '../util/ID';
import { getId } from '../util/ID';

export default function DetailPage() {
    const { sid } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [result, setResult] = useState(null);
    const [value, setValue] = useState(0);

    const tp = searchParams.get('tp') || 1;
    const menu = searchParams.get('menu') || 1;

    // const handleClose = () => {
    //     // const queryParams = new URLSearchParams();
    //     // queryParams.set('page', returnPage);
    //     // queryParams.set('menu', menu); // 保持查詢用的 menu

    //     // 根據 menu 值返回對應的路徑
    //     let returnPath = '/strategy';
    //     navigate(`${returnPath}?${queryParams.toString()}`);
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        navigate('/');
    };

    // const handleClose = () => {
    //     // 使用 navigate(-1) 返回上一頁而不是固定導航到首頁
    //     navigate(-1);
    // };

    useEffect(() => {
        if (sid) {
            fetchData();
        }
    }, [sid]);

    const fetchData = () => {
        let param = {
            bid: sid,
            sn: getId(),
        };

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic");
        }

        param = putInpQueryParam(param);

        fetch("/ocb/v1/stock2/cb/detail2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.length > 0) {
                        setResult(result[0]);
                    }
                }
            );
    };

    if (!result) return null;

    return (
        <div>
            <AppBar elevation={1} color="default" sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {result.cb2.sname}  {result.cb2.bid}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="基本資料" />
                <Tab label="價格與成交量" />
                <Tab label="轉換價值" />
                <Tab label="轉換率" />
                <Tab label="歷史年成交統計 (VIP)" />
            </Tabs>

            {value === 0 && <BasicDetail tp={tp} menu={menu} row={result} />}
            {value === 1 && <AllPrice tp={tp} menu={menu} row={result} />}
            {value === 2 && <NetValue tp={tp} menu={menu} row={result} />}
            {value === 3 && <ChangeRate tp={tp} menu={menu} row={result} />}
            {value === 4 && <HistoryCB tp={tp} menu={menu} row={result} />}
        </div>
    );
}