import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllPrice from './AllPrice';
import BasicDetail from './BasicDetail'
import NetValue from './NetValue'
import ChangeRate from './ChangeRate'
import { getId } from '../util/ID'
import HistoryCB from './HistoryCB';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailDialog1(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const textFieldRef = useRef(null);

    const getShareUrl = () => {
        return `${window.location.origin}/#/detail/${props.row.cb2.bid}`;
    };

    const handleCopyLinkShare = () => {
        // 構建分享連結
        const shareUrl = getShareUrl();

        // 複製到剪貼簿
        navigator.clipboard.writeText(shareUrl).then(() => {
            setSnackbarOpen(true);
        });
    };

    const handleShare = async () => {
        const shareUrl = getShareUrl();

        // 檢查是否支援 Share API
        if (navigator.share) {
            try {
                await navigator.share({
                    title: `${props.row.cb2.sname} ${props.row.cb2.bid}`,
                    url: shareUrl
                });
                return;
            } catch (err) {
                console.log('Share failed:', err);
            }
        }

        // 如果不支援 Share API，嘗試使用 clipboard API
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(shareUrl);
                setSnackbarOpen(true);
                return;
            } catch (err) {
                console.log('Clipboard failed:', err);
            }
        }

        // 後備方案：顯示對話框讓用戶手動複製
        setShareDialogOpen(true);
    };

    // 手動複製文字
    const handleManualCopy = () => {
        if (textFieldRef.current) {
            textFieldRef.current.select();
            try {
                document.execCommand('copy');
                setSnackbarOpen(true);
                setShareDialogOpen(false);
            } catch (err) {
                console.log('Manual copy failed:', err);
            }
        }
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);



    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.cb2.bid,
            tp: props.tp,
            froms: props.menu
        }

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/ocb/v1/stock2/cb/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (props.updateLove != null) props.updateLove(props.row.cb2.bid, props.tp)
                        handleClose()
                    }
                }
            );
    }

    return (
        <div>
            {open && <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar elevation={1} color="default" sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.row.cb2.sname}  {props.row.cb2.bid}
                        </Typography>


                        {isDesktop ? <IconButton
                            color="inherit"
                            onClick={handleCopyLinkShare}
                            sx={{ mr: 1 }}
                        >
                            <ContentCopyIcon />
                        </IconButton> :
                            <IconButton
                                color="inherit"
                                onClick={handleShare}
                                sx={{ mr: 1 }}
                            >
                                <ContentCopyIcon />
                            </IconButton>}


                        {props.row.islv ? <Button startIcon={<FavoriteBorder></FavoriteBorder>} disableElevation variant='contained' onClick={clickLove}>
                            移除最愛
                        </Button> : <Button startIcon={<FavoriteBorder></FavoriteBorder>} disableElevation variant='contained' onClick={clickLove}>
                            加入最愛
                        </Button>}
                    </Toolbar>
                </AppBar>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="基本資料" />
                    <Tab label="價格與成交量" />
                    <Tab label="轉換價值" />
                    <Tab label="轉換率" />
                    <Tab label="歷史年成交統計 (VIP)" />
                </Tabs>

                {value === 0 && <BasicDetail tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 1 && <AllPrice tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 2 && <NetValue tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 3 && <ChangeRate tp={props.tp} menu={props.menu} row={props.row} />}
                {value === 4 && <HistoryCB tp={props.tp} menu={props.menu} row={props.row} />}
            </Dialog>}

            <Dialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
            >
                <DialogContent>
                    <TextField
                        fullWidth
                        value={getShareUrl()}
                        inputRef={textFieldRef}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShareDialogOpen(false)}>取消</Button>
                    <Button onClick={handleManualCopy} variant="contained">
                        複製
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="連結已複製到剪貼簿"
            />
        </div>
    );
}
