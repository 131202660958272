import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { grey, red, green } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles';
import { getId } from '../util/ID'
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import DetailDialog from '../detail/DetailDialog1'
import { Adsense } from '@ctrl/react-adsense';
import { TableSortLabel } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: grey[200],
    color: "#000000",
    // color: theme.palette.common.white,
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));


export default function CBTables(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getOvrate = (row) => {
    if (row.cb2 != null)
      return (((row.cb2.bprice / row.cb2.gprice) - 1) * 100).toFixed(2)
    else return "-"
  }

  const getRateStyle = (row) => {
    let k = getOvrate(row)
    if (k > 10 || k < -3) return "contained"
    else return "outlined"
  }

  const getColor = (row) => {
    if (row != null && row.sprice != null) {
      if (row.sprice.i > 0) return red[400]
      else if (row.sprice.i < 0) return green[400]
      else return null
    } else return null
  }

  const getInv = (row) => {
    if (row != null && row.sprice != null) {
      let pp = ((row.sprice.i * 100) / (row.sprice.p - row.sprice.i)).toFixed(1)

      if (row.sprice.i > 0) return "▲" + row.sprice.i + '元 (' + pp + '%)'
      else if (row.sprice.i < 0) return "▼" + row.sprice.i + '元 (' + pp + '%)'
      else return row.sprice.i + '元' + ' (' + pp + '%)'
    } else return '▲0元'
  }

  const clickLove = (bid) => {
    let param = {
      sn: getId(),
      sid: bid,
      tp: props.tp,
      froms: props.menu
    }

    if (localStorage.getItem("lic") != null) {
      param['lic'] = localStorage.getItem("lic")
    }

    fetch("/ocb/v1/stock2/cb/clicklove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result != null && result.status === 0) {
            // if(props.refresh != null) props.refresh()
            if (props.updateLove != null) props.updateLove(bid, props.tp)
          }
        }
      );
  }

  const openDetail = (row) => {
    setRow(row)
    setOpen(true)
  }

  // const openDetail = (row) => {
  //   navigate(`/detail/${row.cb2.bid}?tp=${props.tp}&menu=${props.menu}`);
  // }


  const rateColor = (row) => {
    let k = ((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu
    if (k > 50) return "error"
    else return "inherit"
  }

  const rateVariant = (row) => {
    let k = ((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu
    if (k > 80) return "contained"
    else if (k > 50) return "outlined"
    else return "text"
  }

  const getNoAd = () => {
    return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
  }

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    let sort = isAsc ? 'desc' : 'asc'

    if (props.updateOrder != null) props.updateOrder(sort, property)
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      {open && row != null && <DetailDialog updateLove={props.updateLove} tp={props.tp} menu={props.menu} row={row} close={setOpen} open={open} />}

      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader size='small' sx={{}} >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ borderRight: "1px solid grey", zIndex: 999, position: "sticky", left: 0, bgcolor: grey[200] }}>名稱</StyledTableCell>
              <StyledTableCell align="center">
                <TableSortLabel onClick={createSortHandler("1")} active={props.orderBy === "1"} direction={props.order} >
                  折溢價
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">
                <TableSortLabel onClick={createSortHandler("2")} active={props.orderBy === "2"} direction={props.order} >
                  CB收盤價
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right"><TableSortLabel onClick={createSortHandler("3")} active={props.orderBy === "3"} direction={props.order} >
                轉換價值
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="center"><TableSortLabel onClick={createSortHandler("4")} active={props.orderBy === "4"} direction={props.order} >
                轉換率
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="right"><TableSortLabel onClick={createSortHandler("5")} active={props.orderBy === "5"} direction={props.order} >
                股票價格
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="right"><TableSortLabel onClick={createSortHandler("6")} active={props.orderBy === "6"} direction={props.order} >
                轉換價
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="center"><TableSortLabel onClick={createSortHandler("7")} active={props.orderBy === "7"} direction={props.order} >
                剩餘年限
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="right"><TableSortLabel onClick={createSortHandler("8")} active={props.orderBy === "8"} direction={props.order} >
                權利金百元報價
              </TableSortLabel></StyledTableCell>
              <StyledTableCell align="center"><TableSortLabel onClick={createSortHandler("9")} active={props.orderBy === "9"} direction={props.order} >
                買回日
              </TableSortLabel></StyledTableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {getNoAd() === "0" && <TableCell colSpan={10}>
              <Adsense
                client="ca-pub-8613274553211553"
                slot="4424041064"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
              />
            </TableCell>}

            {props.result.map((row) => (
              <StyledTableRow key={row.cb2.bid}>
                <StyledTableCell component="th" scope="row" sx={{ borderRight: "1px solid grey", zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[200] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}>
                  <Stack direction={"row"} spacing={0.2} onClick={e => clickLove(row.cb2.bid)}>
                    {row.islv ? <Favorite fontSize='12' color="error" /> :
                      <FavoriteBorderOutlined fontSize='12' />}

                    <Stack direction={"column"} >
                      <Typography fontSize={12}>{row.cb2.sname}</Typography>
                      <Typography fontSize={12}>{row.cb2.bid}</Typography>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" onClick={e => openDetail(row)}>
                  <Button size='small' fullWidth disableElevation variant={getRateStyle(row)} color={row.cb2 != null && row.cb2.bprice > row.cb2.gprice ? "error" : "success"}>{getOvrate(row)}%</Button>
                </StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.bprice}元</StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.gprice}元</StyledTableCell>


                <StyledTableCell align="center" onClick={e => openDetail(row)}>
                  <Button size='small' fullWidth disableElevation variant={rateVariant(row)} color={rateColor(row)}>{(((row.info.oriisu - row.info.rest) * 100) / row.info.oriisu).toFixed(1)}%</Button>
                </StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>
                  <Typography fontSize={14} color={getColor(row)}>{row.sprice != null ? row.sprice.p : "-"}元</Typography>
                  <Typography fontSize={12} color={getColor(row)}>{getInv(row)}</Typography>
                </StyledTableCell>

                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.cprice}元</StyledTableCell>
                <StyledTableCell align="center" onClick={e => openDetail(row)} >{(row.cb2.expiredays / 365).toFixed(2)}年</StyledTableCell>
                <StyledTableCell align="right" onClick={e => openDetail(row)}>{row.cb2.cbas != null && row.cb2.cbas.premium100 != null ? row.cb2.cbas.premium100.toFixed(2) : "-"}元</StyledTableCell>
                <StyledTableCell align="center" onClick={e => openDetail(row)}>{row.cb2.expire_date}</StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
