import React from 'react';
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom';
import CHome from './chome/CHome';
import CHomeM1 from './mhome/CHome';
import CHomeM2 from './ioshome/CHome';
import DetailPage from './detail/DetailPage';  // 新增引入

export default props => (
  <HashRouter>
    <Routes>
      <Route exact path='/detail/:sid' element={<DetailPage />} />  {/* 新增路由 */}
      <Route exact path='/' element={<CHome />} />
      <Route exact path='/gool' element={<CHome />} />
      <Route exact path='/ios' element={<CHome />} />
      <Route exact path='/gool1' element={<CHome />} />
      <Route exact path='/ios1' element={<CHomeM2 />} />
      <Route exact path='/open' element={<CHome />} />

      <Route exact path='/strategy' element={<CHome defaultMenu={2} />} /> {/* 策略 */}
      <Route exact path='/favorites' element={<CHome defaultMenu={11} />} /> {/* 自選股 */}
      <Route exact path='/purchase' element={<CHome defaultMenu={7} />} /> {/* 購買 */}
    </Routes>
  </HashRouter>
)