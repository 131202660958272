import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem'
import RecItem2 from './RecItem2'
import RecItem3 from './RecItem3'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RecItem4 from './RecItem4'
import { putInpQueryParam } from '../util/ID'
import { getId } from '../util/ID'
import RecItem5 from './RecItem5';
import CBTables from './CBTables';
import { Adsense } from '@ctrl/react-adsense';

export default function RecContainer(props) {

    const [page, setPage] = React.useState(1);
    // const [sort, setSort] = React.useState(0);
    // const [count, setCount] = React.useState(50);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null);

    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("-1");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchData1(value)
    };

    const fetchData0 = () => {
        setPage(1)
        fetchData1(1)
    }

    const updateOrder = (order, orderBy) => {
        setPage(1)
        setOrder(order)
        setOrderBy(orderBy)
        fetchData1(1, order, orderBy)
    }

    const fetchData1 = (pg, order, orderBy) => {
        if (menu != null) {
            setLoading(true)

            let sort = localStorage.getItem('search_sort') != null ? localStorage.getItem('search_sort') : '0'
            let light = localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0'
            let count = localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0'
            let rules = localStorage.getItem('cb_search_rules') != null ? JSON.parse(localStorage.getItem('cb_search_rules')) : []

            let param = {
                page: pg,
                sort: sort,
                light: light,
                c: count,
                id: menu,
                sn: getId(),
                rules: rules,
            }

            if (order != null) {
                param['order'] = order
            }

            if (orderBy != null) {
                param['order_by'] = orderBy
            }

            if (localStorage.getItem("lic") != null) {
                param['lic'] = localStorage.getItem("lic")
            }


            param = putInpQueryParam(param)

            fetch("/ocb/v1/stock3/cb/get", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)

                        if (result != null && result.status === 0) {
                            if (result.data != null && result.data.length > 0) {
                                setTotal(result.total)
                                setResult(result.data)
                                setTp(result.tp)
                            } else {
                                setTotal(0)
                                setResult([])
                            }
                        }
                    }
                );
        }
    }

    useEffect(() => {
        if (menu != null) {
            setPage(1)
            fetchData0(1)
        }
    }, [menu]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.cb2.bid === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const getItem = (row) => {
        if (menu === "0" || menu === "1")
            return <RecItem updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
        else if (menu === "2")
            return <RecItem2 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
        else if (menu === "3")
            return <RecItem3 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
        else if (menu === "8")
            return <RecItem4 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
        else if (menu === "9")
            return <RecItem5 updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
        else return <RecItem updateLove={updateLove} tp={tp} key={row.cb2.bid} row={row} menu={menu} />
    }

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }



    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            <RecSelector setMenu={setMenu} refresh={fetchData0} order={order} orderBy={orderBy} updateOrder={updateOrder} />
            {loading && <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box>}

            {!loading && (localStorage.getItem("screen") == null || localStorage.getItem("screen") === "0") && <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                {getNoAd() === "0" && <Grid key={"ad1"} item xs={12} sm={12} md={12} lg={12}>
                    <Adsense
                        client="ca-pub-8613274553211553"
                        slot="4424041064"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />
                </Grid>}

                {result != null && result.map(row => {
                    return <Grid key={row.cb2.bid} item xs={12} sm={6} md={4} lg={3}>
                        {getItem(row)}
                    </Grid>
                })}
                {result != null && result.length === 0 &&
                    <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                }
            </Grid>}

            {!loading && (localStorage.getItem("screen") != null && localStorage.getItem("screen") === "1") && result != null &&
                <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: "hidden" }}>
                    <CBTables order={order} orderBy={orderBy} updateOrder={updateOrder} result={result} updateLove={updateLove} tp={tp} menu={menu} />
                </Box>
            }


            {< Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} page={page} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}

            {/* {child1} */}

        </Box >
    );
}
